<template>
	<v-container class="mb-3" style="border: thin solid;">
    <input
    	type="file"
    	ref="fileInput"
    	style="display: none;"
    	accept="image/jpeg,image/png"
    	@change="fileInputChange"
    >
		<div class="subtitle-1 pa-0 text-center">Прикрепленные файлы</div>
  	<v-row>
  		<v-col cols="3" class="text-center" v-for="(f, i) in value" :key="i">
        <div class=file-item>
          <v-icon
            large
            color="primary"
            @click="removeFile(f)"
            >
            mdi-file-remove
          </v-icon>
          <div style="max-width: 100%;">{{f.name}}</div>
        </div>
  		</v-col>
  		<v-col cols="12" class="text-center">
  			<v-btn color="info" small @click="$refs.fileInput.click()">Добавить файл</v-btn>
  		</v-col>
  	</v-row>
    <FileUploader
      showProgress
      :file="uploadFile"
      @done="uploadDone"
    />
	</v-container>
</template>

<script>
import FileUploader from '@/components/FileUploader.vue'

export default {
  props: {
    value: {
      type: Array,
      default: () => ([])
    }
  },
	components: { FileUploader },
  data: () => ({
    uploadFile: null,
  }),
	methods: {
    uploadDone(e) {
      this.$emit('input', { name: this.uploadFile.name, url: e.url, new: true })
    },
    removeFile(file) {
      if (confirm('Действительно убрать ' + file.name + '?')) {
        this.$emit('removeFile', file)
      }
    },
    fileInputChange(e) {
      const selectedFiles = e.target.files
    	selectedFiles.forEach(sf => {
    		if(!this.value.some(f => f.name === sf.name)) {
    			this.uploadFile = sf
    		} else {
    			alert('Файл ' + sf.name + ' уже загружен')
    		}
    	})
      this.$refs.fileInput.value = ''
    },
	}
}
</script>

<style>
  .file-item {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
</style>
