<template>
  <v-card width="60%" style="margin: 15px auto;">
    <v-card-subtitle class="primary white--text text-center">
      Создание тех. процесса
    </v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <v-text-field
            label="ID/название"
            v-model="process.name"
            >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Группа/объект"
            v-model="process.groupName"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            auto-grow
            filled
            rows="4"
            row-height="30"
            label="Краткое описание тех. процесса"
            v-model="process.about"
          />
        </v-col>
        <v-col cols="6">
          <StaffSearchField
            v-model="accepter"
            :queryParams="{ roles: [1,2,3] }"
            label="Утверждающий"
          />
        </v-col>
        <v-col cols="3" >
          <v-checkbox label="Требуется согласование" v-model="requireAgree"/>
        </v-col>
        <v-col cols="12" v-if="requireAgree">
          <v-row>
            <v-col cols="6">
              <OrganizationSearchField
                v-model="agreederOrg"
                label="Согласующая организация"
              />
            </v-col>
            <v-col cols="6">
              <StaffSearchField
                v-model="agreeder"
                :queryParams="{ orgId: agreederOrgId, roles: [1,2,3] }"
                label="Согласующий"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <Files
        :value="process.files"
        @input="addFile"
        @removeFile="removeFile"
      />
      <Cards v-model="process.cards"/>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        :loading="sending"
        :disabled="sending||process.cards.length === 0"
        @click="send"
        >
        Далее
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import Cards from './components/Cards.vue'
import Files from './components/Files.vue'
import StaffSearchField from '@/components/StaffSearchField.vue'
import OrganizationSearchField from '@/components/OrganizationSearchField.vue'

  export default {
    components: {
      Cards,
      Files, StaffSearchField, OrganizationSearchField
    },
    data: () => ({
      process: {
        name: '',
        groupName: '',
        files: [],
        cards: [],
      },
      sending: false,
      accepter: null,
      agreeder: null,
      agreederOrg: null,
      requireAgree: false,
    }),
    computed: {
      sortedCards() {
        return this.process.cards.sort((a, b) => a.queue > b.queue );
      },
      ...mapState('organization', {
        orgId: state => state.profile.id,
      }),
    },
    methods: {
      removeFile(e) {
        this.axios.post('file/delete', { url: e.url }).then(() => {
          let index = this.process.files.findIndex(f => f.name == e.name)
          this.process.files.splice(index, 1)
        })
      },
      addFile(file) {
        this.process.files.push(file)
      },
      send() {
        let procData = {
          name: this.process.name,
          accepterId: Number(this.accepter.userId),
          groupName: this.process.groupName,
          requireAgree: this.requireAgree,
          cards: this.process.cards.map(c => ({
            cardId: Number(c.id),
            queue: Number(c.queue),
            seam: Number(c.seam),
            code: c.code.toString()
          })),
          files: this.process.files
        }
        if (this.requireAgree) {
          procData.agreederId = Number(this.agreeder.userId),
          procData.agreederOrgId = Number(this.agreederOrg.id)
        }
        this.$axios.post('/manufacture/process-template/create', procData).then(r => {
          this.$router.push('/manufacture/process-template/index-accept')
        })
      },
    },
  }
</script>
