<template>
  <v-dialog value="true" scrollable persistent max-width="100%">
    <v-card>
      <v-card-subtitle class="primary white--text text-center">
        Поиск тех. карт
      </v-card-subtitle>
      <v-card-text>
      <CardsTable>
        <template v-slot:select="{ card }">
          <v-checkbox :value="card" v-model="selectedCards"></v-checkbox>
        </template>
      </CardsTable>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          color="default"
          :loading="sending"
          :disabled="sending"
          @click="close"
          >
          Отмена
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          :loading="sending"
          :disabled="sending"
          @click="select"
          >
          Далее
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import CardsTable from '../../card/components/CardsTable.vue'
export default {
  components: { CardsTable },
  data: () => ({
    sending: false,
    loading: false,
    selectedCards: [],
  }),
  computed: {
    ...mapState('organization', {
      orgId: state => state.profile.id,
    })
  },
  methods: {
    select() {
      this.$emit('done', this.selectedCards)
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>
