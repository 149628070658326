<template>
  <v-autocomplete
    :label="label"
    :items="computeItems"
    v-model="selected"
    :loading="loading"
    :search-input.sync="searchValue"
    item-text="name"
    return-object
    clearable
    :multiple="multiple"
    @change="$emit('input', selected)"
    single-line
    >
    <template v-slot:item="{ item }">
      <v-list-item-avatar>
        <v-img v-if="item.thumbnail" :src="item.thumbnail"/>
        <v-icon v-else size="40">mdi-account-circle</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-html="item.name"></v-list-item-title>
        <v-list-item-subtitle v-html="item.position"></v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
    },
    label: {
      type: String,
      default: 'Поиск персонала'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    removable: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      default: 'organization/staff/index'
    },
    queryParams: {
      type: Object
    }
  },
  data() {
    return {
      items: [],
      selected: this.value,
      searchValue: null,
      searchRequestTimeout: null,
    }
  },
  computed: {
    loading() {
      return !!this.$store.state.apiRequestCounter
    },
    computeItems() {
      if(this.items.length > 0) return this.items
      else if(this.value) return [this.value]
      return []
    },
  },
  methods: {
    remove() {
      this.selected = null
    },
    getItems(value) {
      this.$axios.get(this.url, { params: { ...this.queryParams, filter: value }}).then(r => {
        if (r.data.items) {
          this.items = r.data.items.slice()
        } else {
          this.items = r.data.slice()
        }
        this.items.forEach(item => item.name = item.firstname + ' ' + item.middlename + ' ' + item.lastname)
      })
    },
    select() {
      this.$emit('input', this.selected)
    },
  },
  watch: {
    searchValue(value) {
      if(value && value.length >= 2) {
        if (this.selected && this.selected.name === value) {
          return
        }
        clearTimeout(this.searchRequestTimeout);
        this.searchRequestTimeout = setTimeout(() => this.getItems(value), 1000);
      }
    },
  },
}
</script>
